import React, { useEffect, useState } from "react";
import styled from "styled-components";
import theme from "../../../util/theme";
import { AppText } from "../../ui";
import { mobileSize } from "../../../util/variables";
import { DownloadBadges } from "../../ui/DownloadBadges";
import { getAuth } from "firebase/auth";
import { detectBrowser, Browser } from "../../../util/misc";
import { AppleSSOButton } from "../../ui/AppleSSOButton";
import { GoogleSSOButton } from "../../ui/GoogleSSOButton";
import { FlexDiv } from "../../ui/FlexContainer";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

const renderSSOOption = (loginProvider: "Apple" | "Google" | "Email" | null, email: string) => {
  switch (loginProvider) {
    case "Apple":
      return (
        <ListItem showNumber>
          <BodyText>Select </BodyText>
          <ButtonContainer>
            <AppleSSOButton size="small" />
          </ButtonContainer>
        </ListItem>
      );
    case "Google":
      return (
        <ListItem showNumber>
          <BodyText>Select </BodyText>
          <ButtonContainer>
            <GoogleSSOButton size="small" />
          </ButtonContainer>
        </ListItem>
      );
    case "Email":
      return (
        <ListItem showNumber>
          <BodyText>Sign in with your email: {email}</BodyText>
        </ListItem>
      );
    default:
      return null;
  }
};

const renderEmailText = (
  email: string,
  loginProvider: "Apple" | "Google" | "Email" | null,
  browserInfo: Browser | null,
) => {
  switch (loginProvider) {
    case "Apple":
      return null;
    case "Google":
      if (browserInfo === "Unknown") {
        return (
          <ListItem showNumber={false}>
            <BodyText>with your {email} account</BodyText>
          </ListItem>
        );
      }

      return (
        <ListItem showNumber={false}>
          <BodyText style={{ color: `${theme.SLATE_COLOR}` }}>• Use {email}</BodyText>
        </ListItem>
      );

    default:
      return null;
  }
};

export const ThankYou = () => {
  const [loginProvider, setLoginProvider] = useState<"Apple" | "Google" | "Email" | null>(null);
  const [browserInfo, setBrowserInfo] = useState<Browser | null>(null);
  const [email, setEmail] = useState<string>("");
  useEffect(() => {
    localStorage.removeItem("flow");
  }, []);

  const imageData = useStaticQuery(graphql`
    query CompletePageImageQuery {
      iphone: file(relativePath: { eq: "complete/iphone_14_pro.webp" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      qr: file(relativePath: { eq: "complete/imprint_qr.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      footer: file(relativePath: { eq: "complete/complete_footer.webp" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(user => {
      const email = user?.email;
      if (user) {
        setEmail(email ?? "");
        const { providerData } = user;
        if (providerData.length > 0) {
          const { providerId } = providerData[0];
          switch (providerId) {
            case "apple.com":
              setLoginProvider("Apple");
              break;
            case "google.com":
              setLoginProvider("Google");
              break;
            case "password":
              setLoginProvider("Email");
              break;
            default:
              setLoginProvider("Email");
          }
        }
      } else {
        setLoginProvider("Email");
      }

      setBrowserInfo(detectBrowser());
    });

    return () => unsubscribe();
  }, []);

  const showMobile = () => {
    const showBoth = browserInfo === "Unknown";
    return {
      android: browserInfo === "Android" || showBoth,
      apple: browserInfo === "iOS" || showBoth,
    };
  };

  return (
    <ThankYouContainer>
      <MainContentContainer>
        <HeroImageContainer>
          <HeroImage>
            <Img fluid={imageData.iphone.childImageSharp.fluid} alt="hero image of iphone 14" />
          </HeroImage>
        </HeroImageContainer>
        <InfoContainer>
          <Title>
            Almost there!
            <MobileBreak /> Follow these steps to start using Imprint:
          </Title>
          <OrderList>
            <ListItem showNumber hideOnMobile>
              <FlexDiv direction="column">
                <BodyText>Scan to download, and open the Imprint app</BodyText>
                <QrImage>
                  <Img fluid={imageData.qr.childImageSharp.fluid} alt="imprint qr code" />
                </QrImage>
              </FlexDiv>
            </ListItem>
            <ListItem showNumber hideOnWeb>
              <BodyText>Download and open the Imprint app</BodyText>
            </ListItem>
            <ListItem showNumber>
              <BodyText>Press "Log in" at the bottom of the screen</BodyText>
            </ListItem>
            {renderSSOOption(loginProvider, email)}
            {renderEmailText(email, loginProvider, browserInfo)}
          </OrderList>
          <QuestionTextContainer hideOnMobile>
            <InfoText>
              Questions? Email us at{" "}
              <InfoLink href="mailto:info@imprintapp.com">info@imprintapp.com</InfoLink>
            </InfoText>
          </QuestionTextContainer>
        </InfoContainer>
      </MainContentContainer>
      <DownloadContainer>
        <DownloadBadges showAndroid showApple />
      </DownloadContainer>
      <MobileDownloadContainer>
        <DownloadBadges
          mobileStack
          showAndroid={showMobile().android}
          showApple={showMobile().apple}
        />
      </MobileDownloadContainer>
      <QuestionTextContainer hideOnWeb>
        <InfoText>
          Questions? Email us at{" "}
          <InfoLink href="mailto:info@imprintapp.com">info@imprintapp.com</InfoLink>
        </InfoText>
      </QuestionTextContainer>
      <FooterContainer>
        <Img fluid={imageData.footer.childImageSharp.fluid} alt="footer" />
      </FooterContainer>
    </ThankYouContainer>
  );
};

const ThankYouContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100svh;
  width: 100%;
  align-items: center;
`;

const MainContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 750px;
  width: 100%;
  margin-top: 75px;
  @media ${mobileSize} {
    margin-top: 0px;
  }
`;

const MobileBreak = styled.br`
  display: none;
  @media ${mobileSize} {
    display: inline;
  }
`;

const HeroImageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  height: 100%;
  @media ${mobileSize} {
    display: none;
  }
`;

const HeroImage = styled.div`
  width: 450px;
`;

const QrImage = styled.div`
  width: 196px;
  height: 196px;
  margin: 32px 0px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media ${mobileSize} {
    align-items: center;
    padding: 80px 44px 44px 32px;
    width: 100%;
  }
  > * {
    max-width: 495px;
  }
`;

const InfoText = styled.p`
  font-size: 14px;
  color: ${theme.SLATE_COLOR_2};
  font-weight: 400;
`;

const InfoLink = styled.a`
  text-decoration: underline;
  color: ${theme.SLATE_COLOR_2};
`;

const OrderList = styled.ol`
  padding: 0;
  list-style-type: none;
  counter-reset: item;
`;

const ListItem = styled.li<{ showNumber: boolean; hideOnWeb?: boolean; hideOnMobile?: boolean }>`
  display: flex;
  gap: 16px;
  counter-increment: item;
  align-items: left;
  ${props => props.hideOnWeb && "display: none;"}
  &::before {
    content: ${props => (props.showNumber ? `counter(item) "."` : `""`)};
    font-weight: ${props => (props.showNumber ? 400 : "normal")};
    font-size: ${props => (props.showNumber ? "18px" : "0")};
    font-family: ${props => (props.showNumber ? "theinhardt" : "inherit")};
  }
  @media ${mobileSize} {
    ${props => (props.hideOnMobile ? "display: none;" : "display: flex;")}
    padding-bottom: 16px;
    margin: 0;
    margin-left: ${props => (props.showNumber ? "0" : "16px")};
  }
`;

const Title = styled.h1`
  margin-bottom: 24px;
  font-size: 35px;
  font-weight: 600;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const FooterContainer = styled.div`
  display: none;
  max-height: 400px;
  @media ${mobileSize} {
    display: block;
    height: 400px;
    width: 100%;
  }
`;

const MobileDownloadContainer = styled.div`
  display: none;
  @media ${mobileSize} {
    display: block;
    margin-bottom: 16px;
  }
`;

const DownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  @media ${mobileSize} {
    display: none;
  }
`;

const QuestionTextContainer = styled.div<{ hideOnWeb?: boolean; hideOnMobile?: boolean }>`
  display: ${props => (props.hideOnWeb ? "none" : "flex")};
  @media ${mobileSize} {
    display: ${props => (props.hideOnMobile ? "none" : "flex")};
    flex-direction: column;
    text-align: center;
    margin-bottom: 32px;
  }
`;

const BodyText = styled(AppText as any)`
  font-size: 18px;
  line-height: 24px;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  @media ${mobileSize} {
    line-height: 21px;
  }
  a {
    text-decoration: underline;
    color: ${theme.PRIMARY_COLOR};
  }
`;
